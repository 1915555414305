<template>
  <v-card color="white" class="pa-8">
    <v-card-text class="px-0">
      <div>
        <div class="bsat__title">{{ $t("title") }}</div>

        <section id="overview">
          <div class="bsat__paragraph">{{ $t("overview.paragraph") }}</div>
          <ReadMore>
            <div class="bsat__paragraph">{{ $t("overview.paragraph2") }}</div>
            <div class="bsat__paragraph">{{ $t("overview.paragraph3") }}</div>
            <div class="bsat__paragraph">{{ $t("overview.paragraph4") }}</div>
          </ReadMore>
        </section>

        <!-- <div class="bsat__subtitle mb-4">{{ $t("title2") }}</div>

        <ul class="bsat__paragraph">
          <li
            v-for="(item, i) in summaryUnorderedList"
            :key="i"
            v-text="item"
            class="my-1"
          ></li>
        </ul> -->
        <!-- ************************ IMPLEMENTATION RECOMMENDATIONS ********************************* -->
        <section id="implementationRecommendation">
          <div class="bsat__subtitle mb-4">
            <strong>{{ $t("implementationRecommendations.title") }}</strong>
          </div>

          <ReadMore :intro="$t('implementationRecommendationReadMore')">
            <i18n
              path="implementationRecommendations.task.text"
              tag="div"
              class="bsat__paragraph"
            >
              <template #title>
                <strong>{{
                  $t("implementationRecommendations.task.title")
                }}</strong>
              </template>
            </i18n>

            <div class="bsat__paragraph mb-4">
              <strong>{{
                $t("implementationRecommendations.taskLeader.title")
              }}</strong>
            </div>
            <ul>
              <li
                class="bsat__paragraph mb-4"
                v-for="(item, i) in taskLeaders"
                :key="i"
              >
                {{ item }}
              </li>
            </ul>

            <div class="bsat__paragraph mb-4 font-weight-bold">
              {{ $t("implementationRecommendations.proposedMethods.title") }}
            </div>

            <ul>
              <li
                class="bsat__paragraph mb-4"
                v-for="(p, i) in proposedMethods"
                :key="i"
                v-text="p"
              ></li>
            </ul>
          </ReadMore>
        </section>
        <!-- ********************** RECOMMENDED RESOURCES ************************** -->

        <section id="recommendedResources">
          <div class="bsat__subtitle mb-4">
            {{ $t("recomendedResources.title") }}
          </div>

          <!-- <div class="bsat__paragraph mt-n3 mb-6">
            {{ $t("recommendedResourcesIntro") }}
          </div> -->

          <ul class="ml-0 ml-sm-4 bsat__ul">
            <li>
              <div class="bsat__paragraph">
                {{ $t("recomendedResources.paragraph") }}
              </div>
            </li>

            <li>
              <div>
                <i18n
                  path="recomendedResources.paragraph2.text"
                  tag="div"
                  class="bsat__paragraph"
                >
                  <template #link1>
                    <!-- <router-link to="/update?phase=1&step=2">{{$t("recomendedResources.paragraph2.link1")}}</router-link> -->
                    <a href="/update?phase=1&step=2">{{
                      $t("recomendedResources.paragraph2.link1")
                    }}</a>
                  </template>
                </i18n>
              </div>
            </li>

            <li>
              <div>
                <i18n
                  path="recomendedResources.paragraph3.text"
                  tag="div"
                  class="bsat__paragraph"
                >
                  <template #link>
                    <a @click="goToTab(2)">{{
                      $t("recomendedResources.paragraph3.link")
                    }}</a>
                  </template>
                </i18n>
              </div>
            </li>

            <li>
              <div>
                <i18n
                  path="recomendedResources.paragraph4.text"
                  tag="div"
                  class="bsat__paragraph"
                >
                  <template #title>
                    <strong>{{
                      $t("recomendedResources.paragraph4.title")
                    }}</strong>
                  </template>
                </i18n>

                <a
                  :href="catalogueLink"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <v-btn
                    outlined
                    width="150"
                    height="26"
                    class="bsat__btn"
                    color="dark"
                    >{{ $t("visit") }}</v-btn
                  >
                </a>
              </div>
            </li>

            <li>
              <div>
                <i18n
                  path="recomendedResources.paragraph5.text"
                  tag="div"
                  class="bsat__paragraph"
                >
                  <template #title>
                    <strong>{{
                      $t("recomendedResources.paragraph5.title")
                    }}</strong>
                  </template>
                </i18n>
              </div>
              <router-link
                to="/best-practice-examples-of-bio-based-solutions-with-replicability-potential"
              >
                <v-btn
                  outlined
                  width="150"
                  height="26"
                  class="bsat__btn"
                  dark
                  color="dark"
                  >{{ $t("visit") }}</v-btn
                >
              </router-link>
            </li>
          </ul>
        </section>
        <i18n
          class="mt-8"
          tag="div"
          path="downloadAll.text"
          style="font-family: Montserrat"
        >
          <template #link>
            <a class="dark-text mt-8" :href="zip" target="_blank" download>
              {{ $t("downloadAll.link") }}
            </a>
          </template>
        </i18n>
      </div>
    </v-card-text>
  </v-card>
</template>

<i18n>
{
  "en": {
    "implementationRecommendations": {
      "proposedMethods": {
        "title": "Proposed methods",
        "unorderedList": [
          "At regional policy level, specifically within the established regional interministerial/ interdepartmental group for decision-making processes, it is encouraged to designate a group to draft the document (technical experts).",
          "Gather again the representatives of advisory group. Based on lessons learned, decide if there are new stakeholders to invite, and for which participatory activities.",
          "A consultancy or other expert institutions might be included to facilitate and moderate the participatory process."
        ]
      },
      "task": {
        "text": "{title}: Define bioeconomy strategy update plan",
        "title": "Task"
      },
      "taskLeader": {
        "title": "Task leader",
        "unorderedList": [
          "Involved regional institutions (Interministerial/interdepartmental group) ",
          "Experts in an advisory group and stakeholders previously engaged during first development of strategy and road-map ",
          "(Thereafter, the the designated people from these two groups mentioned before will be called the “strategy update working group”)"
        ]
      },
      "title": "Implementation Recommendations"
    },
    "overview": {
      "paragraph": "Engaging with key stakeholders in close coordination with regional policy makers is instrumental for defining the plan to update the regional strategy. This entails practicalities and nominates chairs and experts in technical discussions and decisions to make. Among others, designating a group in charge of drafting the strategy document (usually technical experts within ministries) and the supporting group for advisory and decision making during the process (usually policy makers from Interministerial group). as Also, it is crucial to define a group in charge of executing the participatory process. This group will design the whole life-span of the  participatory process, from defining the open process to the integration and agreement of the very last comments following a detailed consultation windows along the exercise, addressing both specific targeted experts and general public. Lastly, it is encouraged to take into consideration the lessons learned from the strategy development process.",
      "paragraph2": "Among other topics, the definition of the strategy development plan must consider the following elements;  groups of interest to engage for the strategy update, prioritised themes to be discussed, methodologies of engagement such as workshops or focus groups, and required specific type of information to be collected from interacting  with engaged stakeholders.",
      "paragraph3": "Lastly at this step, it is also expected that regions account for  a monitoring system to oversee and carefully follow the progress of the strategy implementation stages and its analysis for the strategy and roadmap update.",
      "paragraph4": "At this step, be kindly reminded to identify potential experts not involved so far in the process, to actively participate and enrich the advisory group, from industry, regional policy makers, R&D+i or civil society."
    },
    "recomendedResources": {
      "paragraph": "Results of regional bioeconomy strategy implementation (monitoring of KPIs): expected to be existent in the region. ",
      "paragraph2": {
        "link1": "Phase 1, Step 2",
        "text": "Results SAT analysis ({link1})"
      },
      "paragraph3": {
        "link": "Phase 2",
        "text": "RBH´s Guideline (stakeholder involvement in {link})"
      },
      "paragraph4": {
        "text": "{title} Get inspired by the POWER4BIO catalogue of bio-based solutions. In total, the catalogue contains 32 biorefineries, selected by their high market uptake potential and high technical readiness level (TRL) for bioenergy production, biomaterials, biochemicals as well as for food and feed. All factsheets include a short description and several characteristics of the solution, as well as information regarding the suitability of biomass feedstock for production of the target product, benefits of the solution related to the value chain and specific constraints for implementation. The selected content topics of the factsheets are General, Feedstock, Technology, Products, Environment and Impacts.",
        "title": "Catalogue of bio-based solutions:"
      },
      "paragraph5": {
        "text": "{title} Consult this dossier with 12 highlighted bio-based solutions from the POWER4BIO catalogue, which show best practice examples in Europe of industrial production sites. These examples enrich the information collected in the previous resource (catalogue). Thus, the information in this case complements the information of these 12 solutions contained of the same 12 cases in the catalogue. The document which thoroughly describes their potential for replicability in rural areas and and their business cases",
        "title": "Best practice examples:"
      },
      "title": "Recommended resources: "
    },
    "title": "Step 1: Define the strategy and/or roadmap update plan",
    "downloadAll": {
			"link": "click here",
			"text": "*To download all the materials of Phase 3 and Phase 4 as a zip file please {link}"
		}
  }
}
</i18n>

<script>
import ReadMore from "@/components/ReadMore";
import { EventBus } from "@/utils/EventBus";
export default {
  name: "UpdatePhase3Step1",
  components: {
    ReadMore
  },
  data() {
    return {
      bestPractisesFile: require("@/assets/files/1_3_2_2_Best_practice_examples_of_bio_based_solutions_with_replicability_potential.docx")
        .default,
      catalogueLink: "https://www.bio-based-solutions.eu/#/",
      zip: require("@/assets/files/Phase3_Phase4_common_resources.zip").default
    };
  },
  computed: {
    summaryUnorderedList() {
      return Object.values(this.$t("summary.unorderedList"));
    },
    taskLeaders() {
      return Object.values(
        this.$t("implementationRecommendations.taskLeader.unorderedList")
      );
    },
    proposedMethods() {
      return Object.values(
        this.$t("implementationRecommendations.proposedMethods.unorderedList")
      );
    },
    resourcesUnorderedList() {
      return Object.values(this.$t("recomendedResources.unorderedList"));
    }
  },
  methods: {
    goToTab(tab) {
      EventBus.$emit("goToTab", tab);
    },
    goToStep(step) {
      EventBus.$emit("goToStep", step);
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/main";

.list {
  $font-family: $font-family;
  // font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;

  &-link {
    color: $light_green !important;
  }
}
</style>
